import React from "react"
import {Typography, Grid,} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';

import Layout from "../../components/layout"
import { useStaticQuery, graphql } from "gatsby";
import Magazine from './magazineTemplate'


const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    fontFamily:"Arial Black"
    },
}));

const Magazines = () => {
  const classes = useStyles();   
  const data= useStaticQuery(graphql`query {
  allRestApiApiMagazinesShowall {
    edges {
      node {
        data {
          magazines {
            createdAt(locale: "es-MX", formatString: "MMMM YYYY")
            img
            pdf
            title
          }
        }
      }
    }
  }
}`)
 //const monthsES = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_')
  return(
    <Layout>
      <Typography className={classes.margin} variant="h5">SITIMMFORMA</Typography>
      <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
      >
      { 
       data.allRestApiApiMagazinesShowall.edges[0].node.data.magazines.reverse().map( magazine => {
         return( <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Magazine magazine={magazine} />
          </Grid>)
       })}
      </Grid>
    </Layout>
  )
}

export default Magazines



//https://cdn1-www.thefashionspot.com/assets/uploads/gallery/all-the-september-2019-magazine-covers-we-loved-and-hated/8p4xb5zltg945blp.jpg